import clsx from "clsx"
import React from "react"

import {Link, LinkProps} from "~/components/elements/Link"

export type ButtonProps = {
  children: string
  className?: string
  as?: "link" | "input"
  size?: "sm" | "lg"
  active?: boolean
  width?: "fit" | "full"
  variant?:
    | "primary"
    | "secondary"
    | "secondary-alternate"
    | "tertiary"
    | "toggle"
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  Partial<LinkProps>

const ButtonRender: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (
  {
    children,
    className,
    as,
    size = "sm",
    active,
    width = "fit",
    variant = "primary",
    to,
    disabled,
    onClick,
    ...props
  },
  ref,
) => {
  const baseStyles = "flex items-center justify-center duration-100"
  const sizes = {
    sm: "py-[10px] px-[18px] text-[14px] rounded",
    lg: "py-[18px] px-[30px] rounded-lg",
  }
  const widths = {
    fit: "w-fit",
    full: "w-full",
  }
  const variants = {
    primary: clsx(
      "text-white bg-nova",
      !disabled && "hover:bg-mist active:bg-nova",
    ),
    secondary: clsx(
      "text-steel border-smoke border-[1.5px]",
      !disabled &&
        "hover:text-white hover:bg-cosmosgrey hover:border-cosmosgrey active:bg-spacemist",
    ),
    "secondary-alternate": clsx(
      "text-white border-white border-[1.5px]",
      !disabled &&
        "hover:text-cosmosgrey hover:bg-white hover:border-white active:text-white active:bg-transparent",
    ),
    tertiary: clsx(
      "text-white bg-cosmosgrey",
      !disabled && "hover:bg-spacemist active:bg-cosmosgrey",
    ),
    toggle: clsx(
      "border-[1.5px]",
      active
        ? "text-cosmosgrey border-cosmosgrey font-semibold"
        : "text-smoke border-ash",
      !disabled &&
        !active &&
        "hover:text-cosmosgrey hover:border-smoke active:text-cosmosgrey active:border-cosmosgrey",
    ),
  }
  const classes = clsx(
    baseStyles,
    sizes[size],
    widths[width],
    variants[variant],
    disabled ? "opacity-25 cursor-default" : "cursor-pointer",
    className,
  )

  if (as === "link") {
    if (disabled) {
      return (
        <span className={classes} ref={ref}>
          {children}
        </span>
      )
    }

    return (
      <Link {...props} className={classes} to={to ?? ""} ref={ref}>
        {children}
      </Link>
    )
  }

  return (
    <button
      {...props}
      className={classes}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </button>
  )
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ButtonRender,
)
